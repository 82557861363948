const PointlessWords: Set<string> = new Set([
  'the',
  'The',
  'a',
  'A',
  'an',
  'An',
  'and',
  'And',
  'of',
  'Of',
]);

export default PointlessWords;
